<template>
    <div class="quick-create-page">
        <template v-if="activeOrg && !canCreate">
            <div class="engine-page-message flex flex-align-center" style="background-color: #f56c6c;">
                <vip-icon :size="22" :type="activeOrg.accountType"></vip-icon>
                <div class="color-white margin-l-10">
                    {{activeOrg.accountTypeDesc}}最多能创建{{userInfo.featureConfig.customGPTEngineCount || 0}}个个性引擎，你的账户创建数量已达到上限，如需新建个性引擎，<a href="mailto:languagex@besteasy.com" style="color:#fff;text-decoration: underline;">请联系我们</a>
                </div>
            </div>
        </template>
        <div class="engine-page-message" v-else><svg-icon name="ib-trumpet"></svg-icon> 只需要少量典型样例，即可定制自己的个性引擎，立等可取</div>
        <el-card class="engine-page-card">
            <el-form label-position="top" :disabled="formDisabled">
                <el-form-item label="引擎名称" required>
                    <div class="flex flex-align-center">
                        <div class="flex flex-align-end" :disabled="formDisabled">
                            <el-popover placement="bottom-start" trigger="click">
                                <div class="engine-icon-list">
                                    <template v-for="(icon, index) in config.ENGINE_ICON_LIST">
                                        <div class="item" :key="index" @click="iconItemClick(icon)">
                                            <svg-icon :name="`ie-${icon}`"></svg-icon>
                                        </div>
                                    </template>
                                </div>
                                <div slot="reference" class="flex flex-align-center cursor-pointer">
                                    <div class="create-engine-icon">
                                        <svg-icon :name="`ie-${form.icon}`" style="font-size: 20px;"></svg-icon>
                                    </div>
                                    <i class="el-icon-arrow-down fonts-12 margin-l-5"></i>
                                </div>
                            </el-popover>
                        </div>
                        <el-input v-model="form.name" :maxlength="10" style="width:300px;margin-left: 10px;" placeholder="给自己翻译引擎起个名字，不超过10个字符"></el-input>
                    </div>
                </el-form-item>
                <el-form-item required>
                    <template slot="label">语言方向<span class="form-label-tips"></span></template>
                    <language-select ref="languageSelect" :list="languageList" :edit="formDisabled"></language-select>
                </el-form-item>
                <el-form-item required>
                    <template slot="label">引擎标签<span class="form-label-tips">选择1-3个标签</span></template>
                    <el-checkbox-group v-model="form.domains" :max="3" :disabled="formDisabled">
                        <div class="engine-label-grid">
                            <template v-for="(item, index) in engineLabelOptions">
                                <div class="item" :key="index">
                                    <el-checkbox :key="item.key" :value="item.key" :label="item.key">{{item.name}}</el-checkbox>
                                </div>
                            </template>
                        </div>
                    </el-checkbox-group>
                    
                </el-form-item>
                <el-form-item label="引擎描述" style="margin-bottom: 0;">
                    <el-input v-model="form.descr" type="textarea" rows="2" maxlength="100" show-word-limit :placeholder="`基于LanguageX快速训练的个性引擎，Amazing！`"></el-input>
                </el-form-item>
                
            </el-form>

        </el-card>
        <el-card class="engine-page-card">
            <el-form label-position="top" :disabled="formDisabled">
                <el-form-item label="翻译风格">
                    <div class="flex">
                        <div>
                            <el-select v-model="form.styleType" placeholder="选择风格类型" style="width: 125px;">
                                <template v-for="(item, index) in styleTypeOptions">
                                    <el-option :label="item.name" :value="item.key" :key="`styleType-${index}`"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <div class="margin-l-10">
                            <el-select v-model="form.translateType" placeholder="选择翻译类型" style="width: 125px;">
                                <template v-for="(item, index) in translateTypeOptions">
                                    <el-option :label="item.name" :value="item.key" :key="`translateType-${index}`"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <div class="margin-l-10 flex-1">
                            <el-input v-model="form.styleDesc" maxlength="100" show-word-limit placeholder="自定义风格描述，比如“多用长句”"></el-input>
                        </div>
                    </div>
                    
                </el-form-item>
                <el-form-item required style="margin-bottom: 0;">
                    <el-image fit="contain" style="display:none;" ref="gptEngineExample"
                        src="https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/gpt-engine-sample-1.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=2678700498&Signature=gjCx75VZZgY%2FFT7A4p0YpMxJIqY%3D"
                        :preview-src-list="[
                            'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/gpt-engine-sample-1.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=2678700498&Signature=gjCx75VZZgY%2FFT7A4p0YpMxJIqY%3D',
                            'https://nextcat.oss-accelerate.aliyuncs.com/fe_projects/example/gpt-engine-sample-2.png?OSSAccessKeyId=LTAI5tLMrodVLgCBBYxPFDRf&Expires=2678700523&Signature=NxdJI0eZMBikCfSuGHoiGJRFnhs%3D'
                        ]"></el-image>
                    <template slot="label">精选样例<span class="margin-l-16 fonts-12">添加精心挑选的记忆库样例，代表想要的翻译领域和风格，原文译文总字符数不超过4000个<a class="margin-l-10" href="javacript:;" @click="$refs.gptEngineExample.clickHandler()">查看样例格式</a></span></template>
                    <el-input v-model="form.doc" class="doc-textarea" type="textarea" rows="10" maxlength="4000" show-word-limit placeholder="粘贴样例到此，段段对照（原文在上、译文在下），或直接复制excel原文和译文列"></el-input>
                </el-form-item>
            </el-form>
        </el-card>
        <div class="action-content">
            <el-button type="primary" round :loading="formLoading" :disabled="formDisabled" @click="submitEvent">开始训练</el-button>
            <div class="fonts-12 margin-t-10">精选样例原文译文顺序需和语言方向一致</div>
        </div>
        <el-dialog title="创建成功" :visible.sync="successDialogShow" @closed="$router.go(0)" destroy-on-close width="430px" top="20px">
            <div class="padding-20 text-center">
                <div class="color-success fonts-20"><i class="el-icon-success"></i></div>
                <div class="margin-t-15 fonts-15">{{form.name}}引擎已成功创建</div>
                <div class="margin-t-10 fonts-12">可以在引擎列表中直接使用</div>
            </div>
            <div slot="footer" class="text-center">
                <el-button type="primary" size="mini" round @click="successDialogShow = false; $router.push('/engine/config');">去使用</el-button>
                
            </div>
        </el-dialog>
    </div>
</template>
<script>
import config from '@/utils/config';
import LanguageSelect from '@/components/LanguageSelect';
import VipIcon from "@/components/icon/VipIcon";
import { mapState } from 'vuex';
import { myEngineApi } from '@/utils/api';
export default {
    components: { LanguageSelect, VipIcon },
    data () {
        return {
            config,
            canCreate: true,
            formDisabled: false,
            formLoading: false,
            form: {
                name: '',
                icon: '',
                descr: '',
                domains: [],
                styleLabel: [],
                styleType: '1',
                translateType: '1',
                styleDesc: '',
                doc: '',
            },
            languageList: [
                {key:'zh',name:'中文',shortName:'中'},
                {key:'en',name:'英语',shortName:'英'},
            ],
            taskData: {},
            taskTimer: {},
            successDialogShow: false,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            activeOrg: state => state.org.activeOrg,
            engineLabelOptions: state => state.common.engineLabels,
            styleTypeOptions: state => state.common.gptEngineStyleTypes,
            translateTypeOptions: state => state.common.gptEngineTranslateTypes,
        })
    },
    created () {
        this.queryCreatePermission();
        let size = config.ENGINE_ICON_LIST.length;
        this.form.icon = config.ENGINE_ICON_LIST[Math.floor(Math.random()*size)];
        this.$store.dispatch('common/initDictDataAction',{type: 'mt-domains-tags'});
        this.$store.dispatch('common/initDictDataAction',{type: 'mt-style-type'});
        this.$store.dispatch('common/initDictDataAction',{type: 'mt-translate-type'});
    },
    methods: {
        queryCreatePermission(){
            this.$ajax.get(myEngineApi.queryCreateGptPermission).then(res=>{
                if(res.status === 200){
                    this.canCreate = res.data.canCreate;
                    if(!res.data.canCreate){
                        this.formDisabled = true;
                    }
                }
            })
        },
        iconItemClick(icon) {
            this.form.icon = icon;
        },
        changeSelectStyleItem(item) {
            let index = this.form.styleLabel.indexOf(item);
            if(index > -1){
                this.form.styleLabel.splice(index, 1);
            }else {
                this.form.styleLabel.push(item);
            }
        },
        getSelectEngineLabelsName(){
            let rst = [];
            if (this.timerData.engine.domains){
                this.engineLabelOptions.map(item => {
                    if(this.timerData.engine.domains.indexOf(item.key*1) > -1){
                        rst.push(item.name);
                    }
                });
            }
            return rst.join("、");
        },
        submitEvent(){
            let self = this;
            this.lang = this.$refs.languageSelect.getValues();
            // console.log('xxxx:::', this.lang, this.form);
            
            if(!this.form.name){
                this.$message.error('请输入引擎名称');
                return;
            }
            if(!(this.form.domains && this.form.domains.length > 0)){
                this.$message.error('请选择1-3个引擎标签');
                return;
            }
            if(!this.form.doc){
                this.$message.error('请填写精选案例');
                return;
            }
            // return ;
            let url = myEngineApi.createGptEngineTask;
            let postData = {
                source: this.lang.sourceLang,
                target: this.lang.targetLang,
                engineName: this.form.name,
                domains: this.form.domains,
                descr: this.form.descr,
                icon: this.form.icon,
                styles: {
                    styleType: [this.form.styleType],
                    translateType: [this.form.translateType],
                },
                customStyle: this.form.styleDesc,
                corpus: this.form.doc,
            };
            this.formDisabled = true;
            this.formLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.taskData = res.data;
                    this.queryTaskStatus();
                    this.taskTimer = setInterval(()=>{
                        self.queryTaskStatus();
                    }, 5000);
                }
            }).catch(e => {
                this.formDisabled = false;
                this.formLoading = false;
            })

        },
        queryTaskStatus(){
            let url = this.$ajax.format(myEngineApi.getGptTaskStatus, {id: this.taskData.modelId});
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    if(res.data === 'used'){
                        clearInterval(this.taskTimer);
                        this.taskTimer = null;
                        this.formDisabled = false;
                        this.formLoading = false;
                        this.successDialogShow = true;
                    }
                }
            })
        }
    },
    mounted() {
        
    },
}
</script>
<style lang="scss" scoped>
.quick-create-page {
    padding: 12px 0 80px 0;
    width: 860px;
    margin: auto;
    .engine-page-card{
        margin-top: 12px;
        margin-bottom: 10px;
        border-radius: 10px;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
        ::v-deep .el-card__body{
            padding: 30px;
        }
        ::v-deep .el-form-item__label{
            line-height: 20px;
            font-size: 15px;
            color: #000000;
        }
        ::v-deep .el-input__count{
            line-height: 1;
        }
    }
    .form-label-tips{
        color: #E98C02;
        font-size: 12px;
        margin-left: 16px;
    }
    .engine-page-message{
        margin-top: 20px;
        font-size: 14px;
        line-height: 24px;
        padding: 10px 20px;
        background-color: #f7efe0;
        border-radius: 4px;
    }
    .create-engine-icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    [disabled]{
        pointer-events: none;
        opacity: 0.4;
    }
    .engine-label-grid{
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
        line-height: 1;
    }
    .style-grid{
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill,minmax(72px,1fr));
        .item {
            height: 36px;
            background-color: #F8F8F8;
            border: 1px solid transparent;
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.active {
                border-color: #000000;
            }
        }
    }
}
.doc-textarea{
    ::v-deep .el-textarea__inner{
        border-color: #7DD6B3;
    }
}
.engine-icon-list{
    display: flex;
    .item {
        font-size: 24px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            background-color: #f2f2f2;
        }
    }
}
.action-content{
    position: fixed;
    text-align: center;
    padding:10px;
    background-color:#f8f8f8;
    bottom: 0;
    width: 840px;
}
</style>
